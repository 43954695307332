@import "../../../../utils/globals.scss";

$pos-blue: #1fbaff;

.work-retail-hands {
  width: 350px;
  height: 350px;
  margin-bottom: 50px;
  overflow: visible;

  @include for-phone-only {
    width: 250px;
    height: 250px;
  }

  @include for-small-phone-only {
    width: 200px;
    height: 200px;
  }
}

.life-at-shopify {
  text-align: left;
}

.work-pickup {
  width: 800px;
  overflow: visible;
}

.pickup {
  text-align: left;
}

.building-apis {
  text-align: left;
}

.work-pos-graphql {
  width: 800px;
  overflow: visible;
}

/* 
########################################
							ANIMATIONS 
########################################
*/

.work-retail-hands-top {
  animation-name: retail-hands-top-fade;
  animation-duration: 2s;
  animation-delay: 0s;
  animation-timing-function: ease-in;
  animation-iteration-count: 1;

  @keyframes retail-hands-top-fade {
    0% {
      transform: translateY(-10px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

.work-retail-hands-bottom {
  animation-name: retail-hands-bottom-fade;
  animation-duration: 2s;
  animation-delay: 0s;
  animation-timing-function: ease-in;
  animation-iteration-count: 1;

  @keyframes retail-hands-bottom-fade {
    0% {
      transform: translateY(10px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

.work-retail-hands-text {
  animation-name: retail-text-fade;
  animation-duration: 2s;
  animation-delay: 0s;
  animation-timing-function: ease-in;
  animation-iteration-count: 1;

  @keyframes retail-text-fade {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.work-retail-hands-key {
  animation-name: retail-key-fade;
  animation-duration: 3s;
  animation-delay: 0s;
  animation-timing-function: ease-in;
  animation-iteration-count: 1;

  @keyframes retail-key-fade {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.work-pickup-sus-body > path {
  animation-name: work-pickup-sus-colors;
  animation-duration: 28s;
  animation-delay: 0s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;

  @keyframes work-pickup-sus-colors {
    0% {
      fill: $gold;
    }
    7.14% {
      fill: #c51111;
    }
    14.28% {
      fill: blue;
    }
    21.42% {
      fill: green;
    }
    28.56% {
      fill: $pink;
    }
    35.7% {
      fill: orange;
    }
    42.84% {
      fill: yellow;
    }
    49.98% {
      fill: $black;
    }
    57.12% {
      fill: $white;
    }
    64.26% {
      fill: purple;
    }
    71.4% {
      fill: brown;
    }
    78.54% {
      fill: cyan;
    }
    85.68% {
      fill: lime;
    }
    92.82% {
      fill: forestgreen;
    }
    100% {
      fill: tan;
    }
  }
}

.work-pos-graphql-dots__4 {
  animation-name: query4;
  animation-duration: 4s;
  animation-delay: 0s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;

  @keyframes query4 {
    0% {
      opacity: 1;
    }
    12.5% {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    37.5% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    62.5% {
      opacity: 0;
    }
    75% {
      opacity: 0;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      fill: $pos-blue;
      opacity: 1;
    }
  }
}

.work-pos-graphql-dots__3 {
  animation-name: query3;
  animation-duration: 4s;
  animation-delay: 0s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;

  @keyframes query3 {
    0% {
      opacity: 0;
    }
    12.5% {
      opacity: 1;
    }
    25% {
      opacity: 0;
    }
    37.5% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    62.5% {
      opacity: 0;
    }
    75% {
      opacity: 0;
    }
    87.5% {
      fill: $pos-blue;
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

.work-pos-graphql-dots__2 {
  animation-name: query2;
  animation-duration: 4s;
  animation-delay: 0s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;

  @keyframes query2 {
    0% {
      opacity: 0;
    }
    12.5% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    37.5% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    62.5% {
      opacity: 0;
    }
    75% {
      fill: $pos-blue;
      opacity: 1;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
}

.work-pos-graphql-dots__1 {
  animation-name: query1;
  animation-duration: 4s;
  animation-delay: 0s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;

  @keyframes query1 {
    0% {
      opacity: 0;
    }
    12.5% {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    37.5% {
      opacity: 1;
    }
    50% {
      fill: $pos-blue;
      opacity: 1;
    }
    62.5% {
      opacity: 0;
    }
    75% {
      opacity: 0;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
}
