@import "../../utils/globals.scss";

.projects-highlight {
  color: $blue;
}

.blue-underline {
  @include underline($blue);
}

.projects-focus:hover,
.projects-focus:focus {
  /* Visible in the full-colour space */
  box-shadow: 0 0 0 3px rgba($blue, 0.6);

  /* Visible in Windows high-contrast themes */
  outline-color: transparent;
  outline-width: 2px;
  outline-style: dotted;
}

.projects-container {
  margin: 0 auto;

  max-width: 1025px;
  padding: 0 50px 50px;
}

.main-projects-list {
  padding: 0 0 20px 0;
}

.main-projects-list,
.projects__description,
.projects__header {
  text-align: left;
}

.projects-coming-soon {
  max-width: 400px;
  padding: 20px 0 20px 0;
}

.projects__list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.projects__list > div {
  cursor: pointer;
  background-color: #353535;
  width: 300px;
  margin: 20px 15px 20px 15px;
  border-radius: 50px;
  padding: 20px;

  @include for-phone-only {
    width: 250px;
    padding: 20px;
  }

  @include for-small-phone-only {
    width: 200px;
  }
}

.projects__list > div:hover {
  animation-name: pulse-grow-on-hover;
  animation-duration: 0.3s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;

  @keyframes pulse-grow-on-hover {
    to {
      transform: scale(1.1);
    }
  }
}

.projects-icon {
  display: block;
  margin: 0 auto;
  padding: 0 10px 10px 10px;
  width: 150px;

  @include for-phone-only {
    width: 100px;
  }
}

.project-info .projects-icon {
  margin: 0;
}

.project-info {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: left;
  margin: 0 auto;

  max-width: 850px;
  @include for-phone-only {
    width: 100%;
  }
}

.project-info > p,
.project-info > h2 {
  margin-bottom: 0;
}

.project-info__image {
  max-width: 100%;
  height: auto;
  cursor: pointer;
  margin: 20px 0 0 20px;
}

.project-info__tagify-images,
.project-info__tetris-images {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .project-info__image {
    max-width: 25%;

    @include for-phone-only {
      max-width: 75%;
    }
  }

  @include for-phone-only {
    flex-direction: column;
  }
}

.project-info__title {
  text-align: left;
}

.project-info__goals-list {
  display: inline-block;
  list-style-position: inside;
}

.project-info__goals {
  margin-top: 20px;
  margin-bottom: 0;
}

.projects-back-btn {
  max-width: 350px;
  margin: 40px 0;
  border-color: $blue !important;
}

/* 
########################################
							ANIMATIONS 
########################################
*/
.projects-coming-soon-spinner {
  transform-origin: 50% 66.5%;
  animation-name: projects-coming-soon;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  @keyframes projects-coming-soon {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
