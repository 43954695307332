@import "../../utils/globals.scss";

.header-container {
  width: 100%;
  text-align: center;
  padding-top: 25px;
  margin-bottom: 50px;
}

.nav div:nth-child(1),
.nav div:nth-child(3) {
  margin-bottom: 25px;

  @include for-phone-only {
    margin-bottom: 10px;
  }
}

.nav div:nth-child(1) {
  margin-right: 20px;

  @include for-phone-only {
    margin-right: 10px;
  }
}

.nav div:nth-child(3) {
  margin-left: 20px;

  @include for-phone-only {
    margin-left: 10px;
  }
}

.nav div:nth-child(5) {
  margin-right: 50px;

  @include for-phone-only {
    margin-right: 20px;
  }
}

.nav div:nth-child(6) {
  margin-left: 50px;

  @include for-phone-only {
    margin-left: 20px;
  }
}

.nav-item {
  cursor: pointer;
  display: inline-block;
  overflow: visible;
  width: 75px;
  height: 75px;
  border: 2px solid gray;
  border-radius: 50%;
  -moz-border-radius: 50%;
  padding: 5px;

  @include for-phone-only {
    width: 45px;
    height: 45px;
  }

  @include for-small-phone-only {
    width: 35px;
    height: 35px;
  }
}

.nav-item > svg {
  filter: grayscale(100%);
}

.nav-item.active > svg {
  filter: none;
}

.nav-item.pink.active {
  border: 2px solid #dc86ac;
}

// High contrast outline solution is from: https://stackoverflow.com/questions/52589391/css-box-shadow-vs-outline
// Posters: Darek Kay and andrewmacpherson

.nav-item.pink:focus,
.nav-item.pink:hover {
  /* Visible in the full-colour space */
  box-shadow: 0 0 0 3px rgba($pink, 0.6);

  /* Visible in Windows high-contrast themes */
  outline-color: transparent;
  outline-width: 2px;
  outline-style: dotted;
}

.nav-item.pink:focus::after,
.nav-item.pink:hover::after {
  display: block;
  position: absolute;
  content: "ME";
  color: $pink;
  font-weight: bold;
  margin: 15px 0 0 24px;
  height: 8px;
  border-radius: 15px;

  @include for-phone-only {
    margin: 15px 0 0 8px;
  }
}

.nav-item.gold.active {
  border: 2px solid $gold;
}

.nav-item.gold:focus,
.nav-item.gold:hover {
  /* Visible in the full-colour space */
  box-shadow: 0 0 0 3px rgba($gold, 0.6);

  /* Visible in Windows high-contrast themes */
  outline-color: transparent;
  outline-width: 2px;
  outline-style: dotted;
}

.nav-item.gold:focus::after,
.nav-item.gold:hover::after {
  display: block;
  position: absolute;
  content: "WORK";
  color: $gold;
  font-weight: bold;
  margin: 15px 0 0 14px;
  height: 8px;
  border-radius: 15px;

  @include for-phone-only {
    margin: 15px 0 0 -2px;
  }
}

.nav-item.blue.active {
  border: 2px solid $blue;

  .nav-item__projects > path {
    fill: $white;
  }
}

.nav-item.blue:focus,
.nav-item.blue:hover {
  /* Visible in the full-colour space */
  box-shadow: 0 0 0 3px rgba($blue, 0.6);

  /* Visible in Windows high-contrast themes */
  outline-color: transparent;
  outline-width: 2px;
  outline-style: dotted;
}

.nav-item.blue:focus::after,
.nav-item.blue:hover::after {
  display: block;
  position: absolute;
  content: "PROJECTS";
  color: $blue;
  font-weight: bold;
  margin: 15px 0 0 -2px;
  height: 8px;
  border-radius: 15px;

  @include for-phone-only {
    margin: 15px 0 0 -20px;
  }
}

.nav-item.purple.active {
  border: 2px solid $purple;
}

.nav-item.purple:focus,
.nav-item.purple:hover {
  /* Visible in the full-colour space */
  box-shadow: 0 0 0 3px rgba($purple, 0.6);

  /* Visible in Windows high-contrast themes */
  outline-color: transparent;
  outline-width: 2px;
  outline-style: dotted;
}

.nav-item.purple:focus::after,
.nav-item.purple:hover::after {
  display: block;
  position: absolute;
  content: "DESIGN";
  color: $purple;
  font-weight: bold;
  margin: 15px 0 0 10px;
  height: 8px;
  border-radius: 15px;

  @include for-phone-only {
    margin: 15px 0 0 -8px;
  }
}

.nav-item__me {
  width: 75px;
  height: 75px;

  @include for-phone-only {
    width: 45px;
    height: 45px;
  }

  @include for-small-phone-only {
    width: 35px;
    height: 35px;
  }
}

.nav-item__work {
  width: 65px;
  height: 65px;
  padding: 2px 5px 8px 5px;

  @include for-phone-only {
    width: 35px;
    height: 35px;
    padding: 2px 5px 8px 5px;
  }

  @include for-small-phone-only {
    width: 25px;
    height: 25px;
    padding: 2px 5px 8px 5px;
  }
}

.nav-item__projects {
  width: 65px;
  height: 65px;
  padding: 5px 5px 5px 5px;

  @include for-phone-only {
    width: 35px;
    height: 35px;
    padding: 5px 5px 5px 5px;
  }

  @include for-small-phone-only {
    width: 25px;
    height: 25px;
    padding: 5px 5px 5px 5px;
  }
}

.nav-item__design {
  width: 70px;
  height: 70px;
  padding: 2.5px 2.5px 2.5px 2.5px;

  @include for-phone-only {
    width: 40px;
    height: 40px;
    padding: 2.5px 2.5px 2.5px 2.5px;
  }

  @include for-small-phone-only {
    width: 30px;
    height: 30px;
    padding: 2.5px 2.5px 2.5px 2.5px;
  }
}
