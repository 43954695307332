@import "../../../../utils/globals.scss";

.life-at-shopify {
  margin-bottom: 3rem;
}

.localization-content-by-market {
  position: relative;
  text-align: left;
  width: 100%;
}

.localization-csv {
  text-align: left;
  width: 100%;
}

.localization-globe {
  width: 350px;
  height: 350px;
  margin: 0 auto;

  @include for-phone-only {
    width: 250px;
    height: 250px;
  }

  @include for-small-phone-only {
    width: 200px;
    height: 200px;
  }
}

.localization-animation-text {
  color: rgb(71, 193, 191);
  font-size: 2rem;
  letter-spacing: 4.5px;
  margin-top: -40px;

  opacity: 0;
  animation-name: localization-text-fade;
  animation-duration: 3s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;

  @keyframes localization-text-fade {
    0% {
      transform: translateY(-10px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

.localization-csv-translations {
  width: 300px;
  height: 300px;
  margin: 50px auto 150px auto;

  @include for-phone-only {
    width: 250px;
    height: 250px;
  }

  @include for-small-phone-only {
    width: 200px;
    height: 200px;
  }
}

.localization-content-by-market-svg-wrapper {
  margin-left: -55px;

  @include for-phone-only {
    margin-left: -30px;
  }
}

/* 
########################################
							ANIMATIONS 
########################################
*/

.localization-globe {
  animation-name: globe-fade;
  animation-duration: 2s;
  animation-delay: 0s;
  animation-timing-function: linear;
  animation-iteration-count: 1;

  @keyframes globe-fade {
    0% {
      transform: translateY(-10px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  svg {
    overflow: visible;
  }

  .localization__arrow-top,
  .localization__arrow-bottom,
  .localization__text-n-en,
  .localization__text-n-ur,
  .localization__text-n-jap,
  .localization__text-n-kor {
    opacity: 0;
  }
}

.localization__arrow-top {
  animation-name: arrow-top-spin;
  animation-duration: 2s;
  animation-delay: 2s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  transform-origin: 50% 50%;

  @keyframes arrow-top-spin {
    0% {
      transform: rotate(0deg);
      opacity: 0;
    }
    100% {
      transform: rotate(360deg);
      opacity: 1;
    }
  }
}

.localization__arrow-bottom {
  animation-name: arrow-bottom-spin;
  animation-duration: 2s;
  animation-delay: 2s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  transform-origin: 50% 50%;

  @keyframes arrow-bottom-spin {
    0% {
      transform: rotate(0deg);
      opacity: 0;
    }
    100% {
      transform: rotate(360deg);
      opacity: 1;
    }
  }
}

.localization__text-n-en {
  animation-name: text-n-eng;
  animation-duration: 12s;
  animation-delay: 4s;
  animation-timing-function: cubic-bezier(0.42, 0, 0.48, 1);
  animation-iteration-count: infinite;

  @keyframes text-n-eng {
    0% {
      opacity: 1;
    }
    20% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

.localization__text-n-ur {
  animation-name: text-n-ur;
  animation-duration: 12s;
  animation-delay: 4s;
  animation-timing-function: cubic-bezier(0.42, 0, 0.48, 1);
  animation-iteration-count: infinite;

  @keyframes text-n-ur {
    0% {
      opacity: 1;
    }
    20% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

.localization__text-n-jap {
  animation-name: text-n-jap;
  animation-duration: 12s;
  animation-delay: 4s;
  animation-timing-function: cubic-bezier(0.42, 0, 0.48, 1);
  animation-iteration-count: infinite;

  @keyframes text-n-jap {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.localization__text-n-kor {
  animation-name: text-n-kor;
  animation-duration: 12s;
  animation-delay: 4s;
  animation-timing-function: cubic-bezier(0.42, 0, 0.48, 1);
  animation-iteration-count: infinite;

  @keyframes text-n-kor {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.localization-csv-translations-arrow > path {
  animation-name: arrow-fade;
  animation-duration: 4s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  transform-origin: 50% 72%;

  @keyframes arrow-fade {
    25% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(-180deg);
    }
    75% {
      transform: rotate(-180deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
}

.localization-csv-translations-export {
  animation-name: export-fade;
  animation-duration: 4s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  opacity: 0;

  @keyframes export-fade {
    25% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    95% {
      opacity: 0;
    }
  }
}

.localization-csv-translations-import {
  animation-name: import-fade;
  animation-duration: 4s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;

  @keyframes import-fade {
    25% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    75% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.localization-content-by-market-CAN {
  position: absolute;
  left: -55px;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 450px;
  height: 450px;

  @include for-phone-only {
    left: -30px;
    width: 250px;
    height: 250px;
  }

  @include for-small-phone-only {
    width: 200px;
    height: 200px;
  }

  animation-name: CAN-fade;
  animation-duration: 4s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;

  @keyframes CAN-fade {
    25% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
}

.localization-content-by-market-USA {
  margin-left: auto;
  margin-right: auto;
  width: 450px;
  height: 450px;

  @include for-phone-only {
    width: 250px;
    height: 250px;
  }

  @include for-small-phone-only {
    width: 200px;
    height: 200px;
  }

  animation-name: USA-fade;
  animation-duration: 4s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;

  @keyframes USA-fade {
    25% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
}
