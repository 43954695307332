$white: #fcfcfc;
$black: #242424;
$secondary-black: #494949;
$purple: #9c6ade;
$pink: #eba4d8;
$gold: #edc21b;
$blue: #47c1bf;

/* Inserting this collapsed row between two flex items will make 
 * the flex item that comes after it break to a new row 
 From: https://tobiasahlin.com/blog/flexbox-break-to-new-row/ 
 */
.flex-break {
  flex-basis: 100%;
  height: 0;
}

/* 
########################################
							MEDIA QUERY MIXINS 
########################################
*/
@mixin for-small-phone-only {
  @media (max-width: 320px) {
    @content;
  }
}

@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}

@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

/* 
########################################
							GLOBAL MIXINS 
########################################
*/
@mixin underline($color) {
  border-bottom: $color solid 6px;
  margin: 0 0 5px 0;
}

body {
  text-align: center;
}

p {
  font-size: 1.25rem;
}

img {
  pointer-events: all !important;
}

.btn {
  cursor: pointer;
  border-radius: 40px;
  padding: 20px;
  color: $white;
  background-color: transparent;
  border: solid 2px $pink;

  @include for-phone-only {
    font-size: 1rem;
  }

  @include for-tablet-portrait-up {
    font-size: 1.25rem;
  }

  @include for-tablet-landscape-up {
    font-size: 1.5rem;
  }
}

.btn:hover {
  animation-name: pulse-grow-on-hover;
  animation-duration: 0.3s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;

  @keyframes pulse-grow-on-hover {
    to {
      transform: scale(1.1);
    }
  }
}
