@import "../../utils/globals.scss";

.purple-highlight {
  color: $purple;
}

.purple-underline {
  @include underline($purple);
}

.design-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  max-width: 1025px;
  margin: 0 auto;
  text-align: left;
}

.design-container > * {
  padding: 0 50px 0 50px;
}

.design-container-content {
  align-self: center;
  width: 100%;
}

.digital-container-content {
  margin-bottom: 50px;
}

.design-dragon {
  width: 550px;
  height: 550px;
  margin: 0 0 -35px 0;

  @include for-phone-only {
    width: 350px;
    height: 350px;
    padding: 0;
    margin-bottom: 0;
  }

  @include for-small-phone-only {
    width: 200px;
    height: 200px;
  }
}

.design-adobe-monitor {
  width: 300px;
  height: 300px;

  @include for-phone-only {
    width: 250px;
    height: 250px;
    padding: 0;
  }

  @include for-small-phone-only {
    width: 150px;
    height: 150px;
  }
}

/* 
########################################
							ANIMATIONS 
########################################
*/

.design-dragon-body {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: draw-dragon-body 3s linear forwards;

  @keyframes draw-dragon-body {
    to {
      stroke-dashoffset: 0;
    }
  }
}

.design-dragon-spikes {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: draw-dragon-spikes 30s linear forwards;

  @keyframes draw-dragon-spikes {
    to {
      stroke-dashoffset: 0;
    }
  }
}

.design-dragon-wings {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: draw-dragon-wings 6s linear forwards;

  @keyframes draw-dragon-wings {
    to {
      stroke-dashoffset: 0;
    }
  }
}

.design-dragon-eye {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: draw-dragon-eye 20s linear forwards;
  animation-delay: 1.75s;

  @keyframes draw-dragon-eye {
    to {
      stroke-dashoffset: 0;
    }
  }
}

.design-dragon-pupil {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: draw-dragon-pupil 30s linear forwards;
  animation-delay: 2s;

  @keyframes draw-dragon-pupil {
    to {
      stroke-dashoffset: 0;
    }
  }
}

.design-adobe-ai {
  opacity: 0;
  animation-name: show-illustrator;
  animation-duration: 3s;
  animation-delay: 0s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;

  @keyframes show-illustrator {
    0% {
      opacity: 1;
    }

    25% {
      opacity: 1;
    }

    37.5% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }
}

.design-adobe-ps {
  opacity: 0;
  animation-name: show-photoshop;
  animation-duration: 3s;
  animation-delay: 0s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;

  @keyframes show-photoshop {
    0% {
      opacity: 0;
    }

    25% {
      opacity: 0;
    }

    37.5% {
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
  }
}

.design-adobe-left-side {
  animation-name: monitor-fill-left;
  animation-duration: 3s;
  animation-delay: 0s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;

  @keyframes monitor-fill-left {
    0% {
      fill: #93361d;
    }

    25% {
      fill: #93361d;
    }

    37.5% {
      fill: #93361d;
    }

    50% {
      fill: #2d6666;
    }

    100% {
      fill: #2d6666;
    }
  }
}

.design-adobe-right-side {
  animation-name: monitor-fill-right;
  animation-duration: 3s;
  animation-delay: 0s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;

  @keyframes monitor-fill-right {
    0% {
      fill: #60200f;
    }

    25% {
      fill: #60200f;
    }

    37.5% {
      fill: #60200f;
    }

    50% {
      fill: #163d3c;
    }

    100% {
      fill: #163d3c;
    }
  }
}
