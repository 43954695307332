@import "../../utils/globals.scss";

.me-container > *:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba($pink, 0.6);
}

.me-highlight {
  color: $pink;
}

.pink-underline {
  @include underline($pink);
}

.me-container__header {
  width: fit-content;
  margin: 0 auto;
  font-weight: 500;
  padding: 0 20px 0 20px;
  border-left: 2px solid #dc86ac;
  border-right: 2px solid #dc86ac;

  @include for-phone-only {
    border-left: none;
    border-right: none;
  }
}

.btn__me {
  margin-top: 40px;

  @include for-phone-only {
    margin-top: 15px;
  }
}

.me-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;

  max-width: 1025px;
  margin: 0 auto;
}

.me-content > * {
  padding: 20px 50px 20px 50px;
  width: 100%;
}

.core-values-content {
  text-align: left;
}

.core-values-brain {
  max-width: 300px;
}

.me-content-comp-sci {
  text-align: left;
}

.hobbies-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.hobbies-list__games,
.hobbies-list__design,
.hobbies-list__arsenal {
  flex-basis: 25%;
  margin: 30px 30px;
}

.hobbies-icon {
  width: 200px;
  height: 200px;

  @include for-phone-only {
    width: 150px;
    height: 150px;
  }

  @include for-small-phone-only {
    width: 125px;
    height: 125px;
  }
}

.me-container__header-socials {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .me-social-icon {
    width: 50px;
    padding: 0 10px 0 10px;
    margin-top: 10px;
  }
}

/* 
########################################
							ANIMATIONS 
########################################
*/

#prefix__honesty {
  opacity: 0;

  animation-name: honesty-blink;
  animation-duration: 6s;
  animation-delay: 0s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;

  @keyframes honesty-blink {
    33.333% {
      opacity: 1;
    }
    33.4% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
}

#prefix__creativity {
  opacity: 0;

  animation-name: creativity-blink;
  animation-duration: 6s;
  animation-delay: 0s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;

  @keyframes creativity-blink {
    33.333% {
      opacity: 0;
    }
    66.666% {
      opacity: 1;
    }
    66.7% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
}

#prefix__passion {
  opacity: 0;

  animation-name: passion-blink;
  animation-duration: 6s;
  animation-delay: 0s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;

  @keyframes passion-blink {
    66.666% {
      opacity: 0;
    }
    99.999% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

#prefix__glow1 {
  opacity: 0;
  animation-name: glow1-blink;
  animation-duration: 7s;
  animation-delay: 0s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;

  @keyframes glow1-blink {
    14.286% {
      opacity: 1;
    }
    28.572% {
      opacity: 0;
    }
    42.858% {
      opacity: 0;
    }
    57.144% {
      opacity: 0;
    }
    71.43% {
      opacity: 0;
    }
    85.716% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
}

#prefix__glow2 {
  opacity: 0;

  animation-name: glow2-blink;
  animation-duration: 7s;
  animation-delay: 0s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;

  @keyframes glow2-blink {
    14.286% {
      opacity: 0;
    }
    28.572% {
      opacity: 1;
    }
    42.858% {
      opacity: 0;
    }
    57.144% {
      opacity: 0;
    }
    71.43% {
      opacity: 0;
    }
    85.716% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
}

#prefix__glow3 {
  opacity: 0;

  animation-name: glow3-blink;
  animation-duration: 7s;
  animation-delay: 0s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;

  @keyframes glow3-blink {
    14.286% {
      opacity: 0;
    }
    28.572% {
      opacity: 0;
    }
    42.858% {
      opacity: 1;
    }
    57.144% {
      opacity: 0;
    }
    71.43% {
      opacity: 0;
    }
    85.716% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
}

#prefix__glow4 {
  opacity: 0;

  animation-name: glow4-blink;
  animation-duration: 7s;
  animation-delay: 0s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;

  @keyframes glow4-blink {
    14.286% {
      opacity: 0;
    }
    28.572% {
      opacity: 0;
    }
    42.858% {
      opacity: 0;
    }
    57.144% {
      opacity: 1;
    }
    71.43% {
      opacity: 0;
    }
    85.716% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
}

#prefix__glow5 {
  opacity: 0;

  animation-name: glow5-blink;
  animation-duration: 7s;
  animation-delay: 0s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;

  @keyframes glow5-blink {
    14.286% {
      opacity: 0;
    }
    28.572% {
      opacity: 0;
    }
    42.858% {
      opacity: 0;
    }
    57.144% {
      opacity: 0;
    }
    71.43% {
      opacity: 1;
    }
    85.716% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
}

#prefix__glow6 {
  opacity: 0;

  animation-name: glow6-blink;
  animation-duration: 7s;
  animation-delay: 0s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;

  @keyframes glow6-blink {
    14.286% {
      opacity: 0;
    }
    28.572% {
      opacity: 0;
    }
    42.858% {
      opacity: 0;
    }
    57.144% {
      opacity: 0;
    }
    71.43% {
      opacity: 0;
    }
    85.716% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

.controller-blue-glow {
  opacity: 0;
  animation-name: blue-glow;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;

  @keyframes blue-glow {
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

.ipad__left-eye {
  opacity: 0;
  animation-name: draw-left-eye;
  animation-duration: 3s;
  animation-delay: 0s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;

  @keyframes draw-left-eye {
    16.667% {
      opacity: 1;
    }
    33.334% {
      opacity: 1;
    }
    50.001% {
      opacity: 1;
    }
    66.668% {
      opacity: 1;
    }
    83.335% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
}

.ipad__right-eye {
  opacity: 0;
  animation-name: draw-right-eye;
  animation-duration: 3s;
  animation-delay: 0s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;

  @keyframes draw-right-eye {
    16.667% {
      opacity: 0;
    }
    33.334% {
      opacity: 1;
    }
    50.001% {
      opacity: 1;
    }
    66.668% {
      opacity: 1;
    }
    83.335% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
}

.ipad__nose {
  opacity: 0;
  animation-name: draw-nose;
  animation-duration: 3s;
  animation-delay: 0s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;

  @keyframes draw-nose {
    16.667% {
      opacity: 0;
    }
    33.334% {
      opacity: 0;
    }
    50.001% {
      opacity: 1;
    }
    66.668% {
      opacity: 1;
    }
    83.335% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
}

.ipad__mouth {
  opacity: 0;
  animation-name: draw-mouth;
  animation-duration: 3s;
  animation-delay: 0s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;

  @keyframes draw-mouth {
    16.667% {
      opacity: 0;
    }
    33.334% {
      opacity: 0;
    }
    50.001% {
      opacity: 0;
    }
    66.668% {
      opacity: 1;
    }
    83.335% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
}

.arsenal-wheel {
  animation: rotate 1.5s infinite;
  transform-origin: 53% 53%;

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.me-wave-emoji {
  animation-name: wave-grow;
  animation-duration: 2.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;

  @keyframes wave-grow {
    // Animation from: https://jarv.is/notes/css-waving-hand-emoji/
    0% {
      transform: rotate(0deg);
    }
    10% {
      transform: rotate(14deg);
    } /* The following five values can be played with to make the waving more or less extreme */
    20% {
      transform: rotate(-8deg);
    }
    30% {
      transform: rotate(14deg);
    }
    40% {
      transform: rotate(-4deg);
    }
    50% {
      transform: rotate(10deg);
    }
    60% {
      transform: rotate(0deg);
    } /* Reset for the last half to pause */
    100% {
      transform: rotate(0deg);
    }
  }
}
