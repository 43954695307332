@import "../../../../utils/globals.scss";

.avatar {
  width: 300px;
  height: 300px;

  @include for-phone-only {
    width: 200px;
    height: 200px;
  }

  @include for-small-phone-only {
    width: 150px;
    height: 150px;
  }
}

.avatar-animation {
  display: inline-block;
}
