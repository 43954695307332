@import "../../utils/globals.scss";

.work-highlight {
  color: $gold;
}

.gold-underline {
  @include underline($gold);
}

.work-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;

  max-width: 1025px;
  margin: 0 auto;
}

.work-container > * {
  padding: 20px 50px 20px 50px;
}

.work-team {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: 50px;
  border: 2px solid $secondary-black;
  padding: 25px;
  margin-bottom: 20px;

  @include for-phone-only {
    padding: 15px;
  }

  > button {
    color: $white;
    padding: 10px 10px;
    font-size: 1.75rem;
    font-weight: bold;
    font-family: "Raleway";
    cursor: pointer;

    border: none;
    background-color: transparent;
    outline: none;

    @include for-phone-only {
      margin: 0 0 15px 0;
    }
  }

  > button:hover {
    animation-name: pulse-grow-on-hover;
    animation-duration: 0.3s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    @keyframes pulse-grow-on-hover {
      to {
        transform: scale(1.1);
      }
    }
  }

  > button:nth-child(odd) {
    margin-right: 10px;
  }

  button:hover,
  button:focus {
    /* Visible in the full-colour space */
    box-shadow: 0 0 0 3px rgba($gold, 0.6);

    /* Visible in Windows high-contrast themes */
    outline-color: transparent;
    outline-width: 2px;
    outline-style: dotted;
  }

  .teamSelected {
    color: $gold;
    border-bottom: 3px solid $gold;
  }
}

.work-timeline {
  position: relative;
  margin: 0 auto;
}

.work-timeline__header {
  background-color: $black;
  margin-top: -20px;
}

.work-timeline > div {
  background-color: #353535;
  padding: 20px;
  margin: 50px 0 50px 0;
  border-radius: 50px;
}

.work-timeline::after {
  content: "";
  position: absolute;
  width: 6px;
  background-color: $gold;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
  z-index: -1;
}

.work-timeline-icon {
  width: 100px;

  @include for-phone-only {
    width: 75px;
  }
}

.work-timeline-content {
  h2 {
    margin-bottom: 0;
  }
  h3 {
    margin-top: 0;
  }
}
